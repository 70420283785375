import { useRef, useState, useEffect, useCallback, useMemo, useReducer } from 'react';

export const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
};

export function useDidUpdateEffect(fn, dps) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
    }, dps);
}

export function useReducerState(initial = {}) {
    const initialState = useMemo(() => initial, []);
    const callbackQueue = useRef([]);

    const reducer = useCallback(function (state = initialState, action) {
        return { ...state, ...action };
    }, []);

    const [state, dispatch] = useReducer(reducer, initialState);

    useDidUpdateEffect(() => {
        if (callbackQueue.current.length) {
            const cb = callbackQueue.current.shift();
            cb(state);
        }
    }, [state]);

    const setStateWithCallback = useCallback((newState, callback) => {
        dispatch(newState);
        if (callback && typeof callback === 'function') {
            callbackQueue.current.push(callback);
        }
    }, []);

    return {
        state,
        setState: setStateWithCallback
    };
}
