import React, { useState, useEffect } from 'react';

import axios from 'axios';

import avatar from './avatar.png';
// import { Counter } from './features/counter/Counter';

import { useConstructor } from './helper/hooks';
import './App.css';
// 使用环境变量
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function App() {
    const [email, setEmail] = useState('');

    useConstructor(() => {
        console.log('call useConstructor');
    });

    async function getEmail() {
        console.log('call get email');
        try {
            const res = await axios.get(`${API_BASE_URL}/api/email`);
            console.log('email=>', res.data.data);

            setEmail(res.data.data);
        } catch (error) {
            console.log('error=>', error);
        }
    }

    useEffect(() => {
        getEmail();
        console.log('call useEffect');
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                {/* <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span> */}
            </header>

            <div class="area">
                <ul class="circles">
                    {[...Array(10)].map((_, index) => (
                        <li key={index}></li>
                    ))}
                </ul>

                <img src={avatar} className="App-logo" alt="logo" />

                {email && (
                    <p className="Email-link">
                        Email:{' '}
                        <a className="App-link" target="_blank" href={`mailto:${email}`} rel="noreferrer">
                            {email}
                        </a>
                    </p>
                )}
            </div>

            <footer className={'App-footer'}>
                <span className="PSB">
                    <i className="Icp-icon"></i>
                    <a className="Icp-link" href="https://beian.mps.gov.cn/#/query/webSearch?code=51010802032621" rel="noreferrer" target="_blank">
                        川公网安备51010802032621号
                    </a>
                </span>

                <a className="Icp-link" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
                    蜀ICP备2025122935号-1
                </a>
            </footer>
        </div>
    );
}

export default App;
